import Modal from './modules/modal.js'; //quite
import mobileMenu from './modules/mobile-menu.js'; //quite

//Touch sniffer
if ('ontouchstart' in document.documentElement) {
	document.querySelector('html').classList.add('is-touch');
}

// Init functions on pageload
function init() {
	mobileMenu();
	new Modal();
}

jQuery(document).ready(function ($) {
	init();
});

//------------------------------------//
// DELETE ACTIVE STATE
//------------------------------------//
const removeActive = function (selector) {
	[...document.querySelectorAll(selector)].forEach(
		(el) => delete el.dataset.active
	);
};

//------------------------------------//
// TABS
//------------------------------------//
const tabTriggers = document.querySelectorAll('.tab-nav span');

[...tabTriggers].forEach((trigger) => {
	trigger.addEventListener('click', (e) => {
		switchTab(e.currentTarget);
	});
});

function switchTab(trigger) {
	let targetTab = trigger.dataset.tab;

	removeActive('.tab-nav span[data-active]');
	removeActive('.tab[data-active]');

	trigger.dataset.active = true;
	document.querySelector(`.tab[data-tab='${targetTab}']`).dataset.active = true;
}

//------------------------------------//
// TABS - SELECT
//------------------------------------//
// const inputTrigger = document.querySelector('select.tabs-nav');

// inputTrigger &&
// 	inputTrigger.addEventListener('change', (e) => {
// 		e.preventDefault();

// 		let elTarget = e.currentTarget.value;

// 		removeActive('.tab[data-active]');

// 		//trigger.dataset.active = true;
// 		document.querySelector(
// 			`.tab[data-tab='${elTarget}']`
// 		).dataset.active = true;
// 	});
